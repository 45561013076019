import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mapbox"
    }}>{`Mapbox`}</h1>
    <h2 {...{
      "id": "côté-application-mobile"
    }}>{`Côté application mobile`}</h2>
    <p>{`Sur l'application mobile Galidog, `}<a parentName="p" {...{
        "href": "https://www.mapbox.com/"
      }}>{`mapbox`}</a>{` est utilisé pour rendre possible l'affichage d'une cartographie (pour tracer les parcours, afficher la position courante de l'utilisateur, etc.).
Pour cela, l'équipe de `}<a parentName="p" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{` a opté pour cette librairie : `}<a parentName="p" {...{
        "href": "https://github.com/react-native-mapbox-gl/maps"
      }}>{`react-native-mapbox-gl/maps`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Une `}<strong parentName="p">{`API_KEY`}</strong>{` est nécessaire (variable `}<em parentName="p">{`MAPBOX_GL_ACCESS_TOKEN`}</em>{` dans le `}<a parentName="p" {...{
          "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-frontend"
        }}>{`projet`}</a>{`). Pour la récupérer, il faut se rendre sur le compte Mapbox du projet (mais nous l'avons stocké sous le `}<a parentName="p" {...{
          "href": "https://podio.com/"
        }}>{`Podio`}</a>{` de `}<a parentName="p" {...{
          "href": "https://www.troispointzero.fr/"
        }}>{`TroisPointZéro`}</a>{` ).`}</p>
    </blockquote>
    <h2 {...{
      "id": "côté-back-office"
    }}>{`Côté back-office`}</h2>
    <p>{`Dans le `}<a parentName="p" {...{
        "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-backend"
      }}>{`projet`}</a>{`, l'utilisation de mapbox est possible via la variable `}<strong parentName="p">{`MAPBOX_ACCESS_TOKEN`}</strong>{` (qu'il faut récupérer de la même manière sur le compte Mapbox du projet ou via Podio).
Cela permet :`}</p>
    <ul>
      <li parentName="ul">{`de rendre également possible l'affichage d'une cartographie sur l'interface du back-office via `}<a parentName="li" {...{
          "href": "https://docs.mapbox.com/mapbox-gl-js/api/"
        }}>{`Mapbox GL JS`}</a>{`.`}</li>
      <li parentName="ul">{`d'interroger `}<a parentName="li" {...{
          "href": "https://docs.mapbox.com/api/navigation/#directions"
        }}>{`l'API routing`}</a>{` pour calculer un itinéraire entre un point A et un point B.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      